import React from 'react';
import { graphql } from 'gatsby';
import { Container, Grid } from '../components/ui';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import SystemGallery from '../components/SystemGallery';
import SystemDetails from '../components/SystemDetails';
import ContentFeaturedPoints from '../components/ContentFeaturedPoints';
import Tabs from '../components/Tabs';
import ModularBlocks from '../components/ModularBlocks';
import FeaturedSystems from '../components/FeaturedSystems';
import ContactForm from '../components/ContactForm';
import { translateString } from '../utils';

const SystemPageTemplate = ({
  data: { datoCmsSystem },
  pageContext: { locale },
}) => {
  const {
    seoMetaTags,
    slugLocales,
    title,
    subtitle,
    mainImages,
    navImages,
    keyPoints,
    pdfBrochure,
    trialAvailable,
    availableForWorldwideDelivery,
    description,
    keyFacts,
    tabs,
    modularBlocks,
  } = datoCmsSystem;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsSystem}
      verticalText={translateString('Systems', locale)}
    >
      <main>
        <Banner heading={title} locale={locale} isSystemPage={true} />
        <Container>
          <Grid>
            <SystemGallery
              images={{
                mainImages: mainImages,
                navImages: navImages,
              }}
            />
            <SystemDetails
              text={subtitle}
              keyPoints={keyPoints}
              pdfBrochure={pdfBrochure}
              trialAvailable={trialAvailable}
              availableForWorldwideDelivery={availableForWorldwideDelivery}
              locale={locale}
            />
          </Grid>
        </Container>
        <ContentFeaturedPoints
          contentHeading={translateString('System Description', locale)}
          content={description}
          itemsHeading={`${translateString('Key Facts', locale)}:`}
          items={keyFacts}
        />
        <Tabs items={tabs} isSystemPage={true} />
        <ModularBlocks items={modularBlocks} locale={locale} />
      </main>
      <FeaturedSystems
        heading={translateString('Other wastewater systems', locale)}
        locale={locale}
      />
      <ContactForm
        heading={translateString('Contact us', locale)}
        text={translateString(
          'Complete the following form and we’ll be in touch',
          locale
        )}
        locale={locale}
      />
    </Layout>
  );
};

export const SystemPageTemplateQuery = graphql`
  query SystemPageTemplateQuery($id: String!) {
    datoCmsSystem(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      subtitle
      mainImages: images {
        fluid(
          maxHeight: 940
          maxWidth: 770
          imgixParams: { auto: "compress", fit: "crop", h: "940", w: "770" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      navImages: images {
        fluid(
          maxHeight: 130
          maxWidth: 130
          imgixParams: { auto: "compress", fit: "crop", h: "130", w: "130" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      keyPoints
      pdfBrochure {
        url
      }
      trialAvailable
      availableForWorldwideDelivery
      description
      keyFacts
      tabs {
        id
        heading
        content
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentListModularBlockFragment
        ...ContentModularBlockFragment
        ...FeaturedPointsModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...MediaContentListModularBlockFragment
      }
      ...LinkFragment
    }
  }
`;

export default SystemPageTemplate;
