import React, { useRef } from 'react';
import Img from 'gatsby-image';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
} from '../styles';

const StyledSystemGallery = styled.section`
  ${maxBreakpointQuery.smedium`
    margin-right: -30px;
    margin-left: -30px;
  `}

  ${minBreakpointQuery.smedium`
    display: grid;
    gap: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 80px 1fr;
  `}
`;

const StyledImages = styled(SlickSlider)`
  overflow: hidden;
  background-color: ${standardColours.white};
  pointer-events: none;

  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }
`;

const StyledImage = styled(Img)`
  display: block !important;
  height: 100%;
`;

const StyledNavImages = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(5, 1fr);
  `}

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(6, 1fr);
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 1fr;
    grid-row: 1/2;
    grid-template-rows: repeat(5, 80px);
  `}
`;

const StyledNavImage = styled.div`
  background-color: ${standardColours.white};
  cursor: pointer;
`;

const SystemGallery = ({ images: { mainImages, navImages } }) => {
  const slider = useRef();

  const sliderOptions = {
    arrows: false,
  };

  return (
    <StyledSystemGallery>
      {mainImages && (
        <>
          <StyledImages ref={slider} {...sliderOptions}>
            {mainImages.map(({ fluid, alt }, i) => (
              <StyledImage key={`main-image-${i}`} fluid={fluid} alt={alt} />
            ))}
          </StyledImages>
          {navImages.length && (
            <StyledNavImages>
              {navImages.map(({ fluid, alt }, i) => (
                <StyledNavImage
                  onClick={() => {
                    slider.current.slickGoTo(i);
                  }}
                >
                  <Img key={`nav-image-${i}`} fluid={fluid} alt={alt} />
                </StyledNavImage>
              ))}
            </StyledNavImages>
          )}
        </>
      )}
    </StyledSystemGallery>
  );
};

export default SystemGallery;
