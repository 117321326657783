import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  standardTransition,
} from '../styles';
import { Svg, QuoteButton } from './ui';
import { translateString } from '../utils';
import fileDownloadIcon from '../images/file-download-icon.svg';

const StyledSystemDetails = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${brandColours.octonary};
`;

const StyledInner = styled.div`
  padding: 30px 40px;
`;

const StyledText = styled.p`
  line-height: 1.5;
`;

const StyledPoints = styled.ul`
  padding: 10px 0;
`;

const StyledPoint = styled.li`
  padding-top: 14px;
  padding-bottom: 14px;
  line-height: 1.5;
  border-bottom: 1px dashed ${brandColours.primary};

  ${minBreakpointQuery.small`
    padding-top: 18px;
    padding-bottom: 18px;
  `}
`;

const StyledDownload = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 10px;
  padding: 18px;
  ${fontSize(14)};
  background-color: ${brandColours.octonary};

  svg {
    margin-right: 10px;
    height: 22px;
    width: 18px;
    fill: ${standardColours.primary};
  }

  span {
    border-bottom: 1px solid ${standardColours.transparent};
    transition: ${standardTransition('border-bottom')};
  }

  &:hover {
    span {
      border-bottom-color: ${brandColours.primary};
    }
  }
`;

const StyledHighlightedPoints = styled.ul``;

const StyledHighlightedPoint = styled.li`
  margin: 10px 0;
  padding: 18px;
  ${fontSize(14)};
  text-align: center;
  background-color: ${brandColours.quinary};
`;

const StyledButton = styled(QuoteButton)`
  margin-top: auto;
  width: 100%;
  border-radius: 0;
`;

const SystemDetails = ({
  text,
  keyPoints,
  pdfBrochure,
  trialAvailable,
  availableForWorldwideDelivery,
  locale,
}) => (
  <StyledSystemDetails>
    <StyledInner>
      {text && <StyledText>{text}</StyledText>}
      {keyPoints && (
        <StyledPoints>
          {JSON.parse(keyPoints).map(keyPoint => (
            <StyledPoint>{keyPoint}</StyledPoint>
          ))}
        </StyledPoints>
      )}
      {pdfBrochure && (
        <StyledDownload href={pdfBrochure.url} download>
          <Svg image={fileDownloadIcon} />
          <span>Download PDF Brochure</span>
        </StyledDownload>
      )}
      {(trialAvailable || availableForWorldwideDelivery) && (
        <StyledHighlightedPoints>
          {trialAvailable && (
            <StyledHighlightedPoint>
              {translateString(
                'You can request a trial for this system',
                locale
              )}
            </StyledHighlightedPoint>
          )}
          {availableForWorldwideDelivery && (
            <StyledHighlightedPoint>
              {translateString('Available for worldwide delivery', locale)}
            </StyledHighlightedPoint>
          )}
        </StyledHighlightedPoints>
      )}
    </StyledInner>
    <StyledButton locale={locale} />
  </StyledSystemDetails>
);

export default SystemDetails;
